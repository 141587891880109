export const Arabic = {
  there_are_no_data: "لا يوجد بيانات",
  effective_date: "تاريخ الفاعلية",
  increment: "زيادة",
  bank: "البنك",
  assign_allowances: "تعيين البدلات",
  assign_date: "تاريخ التعيين",
  fees: 'رسوم',
  fee: 'رسوم',
  basic_salary: "راتب اساسي",
  allowances: "بدلات",
  allowance_name: "اسم البدل",
  has_allowances: "لديه بدلات",
  allowances_setting: "إعداد البدلات",
  payment_start_date: "تاريخ بدء الدفع",
  freez: "تجميد",
  not_started: "لم يتم البدء",
  allowances_options: "خيارات البدلات",
  allowances_in_vacations: "البدلات في الإجازات",
  allowances_in_sick_leave: "البدلات في الإجازة المرضية",
  allowances_not_in_vacations: "البدلات غير في الإجازات",
  allowances_not_in_sick_leave: "البدلات غير في إجازة مرضية",
  excluded_employees_from_setting: "الموظفون المستبعدون من الإعداد",
  increase: "زياده",
  no_of_installments: "عدد الأقساط", 
  installments: "الأقساط", 
  loans: "قروض",
  loan: "قرض",
  remaining_installment: "القسط المتبقي",
  has_loans: "لديه قروض",
  loans_history: "تاريخ القروض",
  human_resources: "الموارد البشرية",
  holiday: "أجازات",
  passport_no: "رقم جواز السفر",
  hr_name: "اسم الHr",
  employer_name: "اسم صاحب العمل",
  employee_letters: "خطابات الموظف",
  login_details: "تفاصيل تسجيل الدخول",
  passport_expiry_date: "تاريخ انتهاء صلاحية جواز السفر",
  date_of_join: "تاريخ الانضمام",
  personal_informations: "معلومات شخصية",
  benefits: "فوائد",
  bonus: "مكافأة",
  annual: "سنوي",
  attendance: "الحضور",
  admin_updated_successfully: "تم تحديث المشرف بنجاح",
  provision: "توفير",
  paid: "مدفوع",
  track_id: "Track ID",
  tran_id: "Tran ID",
  date: "اليوم",
  not_paid: "غير مدفوع",
  maintaince_start_date: "تاريخ بدأ الصيانه",
  maintaince_end_date: "تاريخ انتهاء الصيانه",
  indemnity: "تعويض",
  contract_no: "رقم العقد",
  start_day: "يوم البدء",
  expiry_date: "تاريخ الانتهاء",
  nationality: "الجنسيه",
  employee_credentials: "بيانات اعتماد الموظف",
  package_num: "رقم الحزمة",
  salary_certificate: "شهادة الراتب",
  company_projects: "مشاريع الشركة",
  existing_projects: "المشاريع القائمة",
  hosting_projects: "المشاريع المستضيفة",
  hosting: "مستضيفة",
  server: "الخادم",
  leave_balance: "رصيد الإجازة", 
  on_vaction: "في الإجازة",
  vacation_history: "تاريخ الإجازة",
  history: "تواريخ",
  approved: "تمت الموافقه",
  rejected: "تم رفضه",
  approve: "موافقة",
  reject: "رفضت",
  leads: 'قيادات',
  all: "الكل",
  cancellation_of_resignation: "إلغاء الاستقالة",
  notice_period: "فترة إشعار",
  resignation: "استقاله",
  start_resignation_date: "تاريخ بدء الاستقالة",
  end_resignation_date: "تاريخ انتهاء الاستقالة",
  lead_name: 'اسم القياده',
  reference: 'مرجع',
  revenues: "ايرادات",
  delete_date: "تاريخ الحذف",
  year: "السنه",
  year_revenues: "الايرادات السنويه",
  custody: "العهده",
  expenses: "مصاريف",
  year_expenses: "مصاريف السنويه",
  financial: "المالي",
  client_email: "بريد الكتوني العميل",
  total_salaries: 'إجمالي الرواتب',
  contract_date: "ميعاد العقد",
  upload_contract: "تحميل العقد",
  client_civil_id: "الرقم المدني للعميل",
  att_alert: "يرجي ادخال صورتين",
  from_front: "من الأمام",
  from_back: "من الخلف",
  freeze: "تجميد",
  profits: "الأرباح",
  after: "بعد",
  payment_link: "رابط الدفع",
  projects: "مشاريع",
  employees_on_vacation: "الموظفين في إجازة",
  personal_email: "بريد الكتروني الشخصي",
  company_email: "بريد الكتروني الشركه",
  company: "الشركه",
  project: "مشروع",
  deduction: "الخصم",
  project_name: "اسم المشروع",
  client_name: "اسم العميل",
  employee_name: "اسم الموظف",
  client_phone: "هاتف العميل",
  salary: "راتب",
  salaries: "رواتب",
  maintaince: 'صيانه',
  department: "قسم",
  emergency_leave: "الإجازة الطارئة",
  annual_leave: "الإجازة السنوية",
  reason: "السبب",
  departments: "أقسام",
  assets: "أصول",
  employees: "الموظفين",
  employee: "الموظف",
  request_sick_leave : "لقد قدم طلبات للحصول على إجازة مرضية لمده",
  request_salary_certificate : "لقد قدم طلبًا لإصدار شهادة راتب",
  is_requesting: "يطلب",
  for: 'لمده',
  days: 'أيام',
  choiceـshowroom: "اختيار صاله العرض",
  dashboard: "الصفحه الرئيسيه",
  admins: "المدراء",
  admin: "المشرف",
  rules: "صلاحيات",
  users: "المستخدمين",
  categories: "التصنيفات",
  category: "التصنيف",
  sub_categories: "التصنيفات الفرعيه",
  sub_category: "التصنيف الفرعي",
  brands: "العلامات التجارية",
  variant: "اضافات",
  dynamic_variant: "اضافات بسعر",
  products: "المنتجات",
  product: "المنتج",
  digital_products: "المنتجات الرقمية",
  reports: "التقارير",
  notification: "الاشعارات",
  time_slot: "اوقات العمل",
  banners: "لافتات",
  website_banners: "لافتات الموقع",
  members: "أعضاء",
  ad_screen: "اعلانات",
  job: "وظيفه",
  job_title: "المسمي الوظيفي",
  control: "تحكم",
  setting: "اعدادات",
  delivery: "توصيل",
  social_media: "وسائل التواصل",
  social: "وسائل التواصل",
  currency: "العمله",
  payment: "الدفع",
  pages: "الصفحات",
  total_users: "إجمالي المستخدمين",
  total_categories: "إجمالي التصنيفات",
  total_sub_categories: "إجمالي التصنيفات",
  total_brands: "إجمالي العلامات التجارية",
  total_admins: "إجمالي المدراء",
  gmail: "بريد إلكتروني",
  total_activities_and_events: "إجمالي الأنشطة والفعاليات",
  total_board_of_directors: "إجمالي مجلس الإداره",
  total_branches_and_markets: "إجمالي الفروع والأسواق",
  total_news: "إجمالي الأخبار",
  total_offers: "إجمالي العروض",
  on_the_way: "في الطريق",
  canceled: "ألغيت",
  delivered: "تم التوصيل",
  shipped: "تم الشحن",
  process: "يتم المعالجه",
  ordered: "تم الطلب",
  total_sales: "إجمالي المبيعات",
  total_salary: "إجمالي المرتب",
  sales: "المبيعات",
  daily: "يومي",
  add_admin: "إضافة مشرف",
  add_admins: "إضافة مشرفين",
  name: "الإسم",
  first_name: "الإسم الاول",
  last_name: "الإسم الآخير",
  email: "بريد إلكتروني",
  address: "الموقع",
  address_link: "رابط الموقع",
  control: "التحكم",
  vacation: "أجازة",
  vacations: "الأجازات",
  accrued_leave: "أجازات مستحقه",
  leave: "أجازات",
  sick_leave: "أجازة مرضية",
  is_sick: "مريض",
  sick: "مريض",
  sick_history: "التاريخ المرضي",
  departure_day: "يوم الرحيل",
  return_day: "يوم العوده",
  diwans: "الدواوين",
  shareholders: "المساهمين",
  shareholders_requests: "طلبات المساهمين",
  requests: "الطلبات",
  attachments: "مرفقات",
  number_of_people: "عدد الافراد",
  people: "عدد الافراد",
  number_of_days: "عدد الايام",
  print: "طباعه",
  membership_number: "رقم العضويه",
  item_no: "رقم الصنف",
  item: "صنف",
  items: "أصناف",
  barcode: "باركود",
  product_name: "اسم المنتج",
  import: "استيراد",
  phone: "رقم التليفون",
  password: "الرقم السري",
  new_password: "الرقم السري الجديد",
  confirm_password: "تأكيد الرقم السري",
  user_name: "اسم المستخدم",
  note: "ملحوظه",
  change_password: "تغيرر الرقم السري",
  country_code: "كود الدوله",
  permissions: "الأذونات",
  unpaid_vacation: "إجازة غير مدفوعة الأجر",
  loan_installment: "قسط القرض",
  status: "حالة",
  details: 'تفاصيل',
  payroll_salary: "كشف الرواتب",
  salary_slip: "وصل الراتب",
  rule: "إذن",
  full_time: "دوام كامل",
  part_time: "دوام جزئي",
  full_permissions: "أذونات كاملة",
  civil_id: "الرقم المدني",
  box_number: "رقم الصندوق",
  family_card: "بطاقة العائلة",
  shareholder_code_number: "كود رقم المساهم",
  some_permissions: "بعض الأذونات",
  no_permissions: "لا أذونات",
  new: "جديد",
  works_hours: "ساعات العمل",
  works_day: "أيام العمل",
  read_only: "قراءه فقط",
  edit: "تعديل",
  delete: "مسح",
  search_by: "البحث عن طريق",
  profile: "الحساب الشخصي",
  logout: "تسجيل الخروج",
  cancel: "إلغاء",
  submit: "إضافه",
  add: "إضافه",
  view: "رؤية",
  select: "اختار",
  deleted: "تم الحذف",
  next: "التالي",
  previous: "السابق",
  go_to_page: "الذهاب الي صفحه",
  page: "صفحه",
  of: "من",
  add_categories: "إضافة تصنيف",
  active: "نشط",
  not_active: "غير نشط",
  image: "الصوره",
  month: "الشهر",
  remove_image: "تم مسح الصوره",
  deactive: "تعطيل",
  english: "اللغه الانجليزيه",
  arabic: "اللغه العربيه",
  category_image: "صوره التصنيف",
  close: " اغلاق",
  price: "السعر",
  in_stock: "متوفره",
  english_title: "العنوان باللغة الانجليزية",
  english_name: "الإسم باللغة الانجليزية",
  arabic_title: "العنوان باللغة العربية",
  arabic_name: "الإسم باللغة العربية",
  english_description: "الوصف باللغة الإنجليزية",
  arabic_description: "الوصف باللغة العربية",
  description: "الوصف",
  activities: "الأنشطه",
  activity: "نشاط",
  branches_and_markets: "الفروع والأسواق",
  code: "كود المنتج",
  cost: "التكلفه",
  quantity: "كمية",
  weight: "وزن",
  offer_price: "سعر العرض",
  best_seller: "الأكثر مبيعًا",
  new_in: "الجديد في",
  offer: "عرض",
  offers: "العروض",
  contact_us: "تواصل معنا",
  talabat: "طلبات",
  images: "الصور",
  create: "إنشاء",
  you_tube: "يوتيوب",
  types_by_english: "أنواع باللغة الإنجليزية",
  types_by_arabic: "أنواع باللغة العربية",
  add_new_value: "إضافة قيمة جديدة",
  chosse: "اختيار",
  has_amount: "لديه كميه",
  amount: "الكميه",
  available_amount: "الكميه المتاحه",
  payment_method: "طريقه الدفع",
  kwd: "دينار كويتي",
  total_price: "السعر الكلي",
  sub_price: "السعر الجزئي",
  total: "الكلي",
  customer_name: "إسم العميل",
  exist_user: "عميل موجود",
  new_user: "عميل جديد",
  search: "بحث",
  order_day: "يوم التوصيل",
  from: "من",
  to: "إلي",
  coupon: "كود الخصم",
  verified: "تم التحقق",
  updated_successfully: "تم التحديث بنجاح",
  updated_status_successfully: "تم تحديث الحالة بنجاح",
  profile_updated_successfully: "تم تحديث الملف الشخصي بنجاح",
  banners_updated_successfully: "تم تحديث اللافتات بنجاح",
  banners_added_successfully: "تم إضافه اللافتات بنجاح",
  apply: "تطبيق",
  enter: "أدخل",
  add_new_product: "إضافه منتج جديد",
  date_range: "المده",
  export: "تصدير",
  visa: "فيزا",
  cash: "كاش",
  type: "النوع",
  end_date: "تاريخ الإنتهاء",
  start_date: "تاريخ البدأ",
  max_usage: "الاستخدام الأقصى",
  count_usage: "عدد الإستخدام",
  percentage: "نسبة مئوية",
  fixed: "نسبة ثابته",
  net_profit: "صافي الربح",
  or: "أو",
  serial_number: "الرقم التسلسلي",
  serial_image: "الصورة التسلسلية",
  title: "العنوان",
  message: "الرساله",
  send: "إرسال",
  organization: "المنظمة",
  organization_attachments: "مرفقات المنظمة",
  statics: "الإحصائيات",
  delete_message:
    "يرجى ملاحظة أنه لا يمكن التراجع عن هذا الإجراء وسيتم حذف التفاصيل.",
  welcome_back: "مرحبا بعودتك",
  sign_text: "قم بتسجيل الدخول عن طريق إدخال المعلومات أدناه",
  text: "أعمال جيدة جدا في انتظاركم",
  sign_in: "اهلا بعودتك! تسجيل الدخول",
  login: "تسجيل الدخول",
  specific_block: "يوم العطله",
  capacity: "سعه",
  crm: "إدارة علاقات العملاء",
  interval: "فاصلة",
  open: "فتح",
  rate: "معدل",
  country_name: "اسم الدولة",
  country: "الدولة",
  banner: "لافته",
  link: "رابط",
  ad: "إعلان",
  add_new_screen: "إضافه شاشه أخري",
  website_title: "عنوان موقع الويب",
  website_logo: "شعار موقع الويب",
  mobile_logo: "شعار الجوال",
  dashboard_logo: "شعار لوحة المعلومات",
  color: "اللون",
  label_color: "لون النصوص",
  possible_delivery: "امكانيه التوصيل",
  cash_in_delivery_fees: "رسوم تسليم النقد",
  shipping_fee: "رسوم الشحن",
  price_per_kg: "السعر للكيلو جرام",
  type_of_area: "نوع المنطقة",
  all_area: "لكل المناطق",
  per_area: "للمنطقة",
  delivery_fees: "رسوم التوصيل",
  facebook: "فيسبوك",
  snapchat: "سناب شات",
  instagram: "إنستغرام",
  whatsapp: "واتس اب",
  twitter: "تويتر",
  with: 'مع',
  and: 'و',
  linked_in: "لينكد إن",
  telegram: "تيليجرام",
  tiktok: "تيك توك",
  app_store: "متجل أبل",
  play_store: "متجر جوجل",
  call_us: "اتصل بنا",
  iban: "رقم الآيبان",
  about: "حول",
  faqs: "الأسئلة الشائعة",
  privacy: "الخصوصية",
  address_name: "العنوان",
  block: "كتلة",
  street: "الشارع",
  area: "المنطقة",
  first: "أولا",
  avenue: "الجادة",
  building_number: "رقم المبنى",
  floor_number: "رقم الطابق",
  office_number: "رقم المكتب",
  house_number: "رقم المنزل",
  apt_number: "رقم الشقة",
  other_instructions: "تعليمات أخرى",
  delivery_day: "يوم التوصيل",
  time: "الوقت",
  confirm_added: "تأكيد الإضافة",
  added: "إضافة",
  confirm_des: "يمكنك إضافة منتج آخر عن طريق تغيير بعض المعلومات",
  add_more_products: "أضف المزيد من نفس المنتج",
  reference_id: "الرقم المرجعي",
  invoice_id: "رقم الفاتورة",
  post_date: "التاريخ",
  createdAt: "أنشئت في",
  order_id: "رقم الطلب",
  static_pages: "الصفحات الثابته",
  field_required: "هذا الحقل مطلوب",
  ordered_successfully: "تم الطلب بنجاح",
  successfully: "بنجاح",
  ordered_failed: "فشل الطلب",
  failed_msg: "عمليه الدفع لم تكتمل",
  back_home: "العودة إلى الصفحة الرئيسية",
  knet: "كي نت",
  bidding_management: "إدارة عروض الأسعار",
  app_commission_management: "إدارة عمولة التطبيق",
  wallet_management: "إدارة المحفظة",
  brand_management: "إدارة العلامة التجارية",
  time_management: "إدارة الوقت",
  location: "الموقع",
  website: "موقع إلكتروني",
  january:'يناير', 
  february: 'فبراير', 
  march: 'مارس', 
  april: 'ابريل', 
  may: 'مايو', 
  june: 'يونيو', 
  july: 'يوليو', 
  august: 'أغسطس', 
  september: 'سبتمبر', 
  october: 'أكتوبر', 
  november: 'نوفمبر', 
  december: 'ديسمبر',
  daily_indemnity: "التعويض اليومي",
  exceeding_years_indemnity: "تعويض السنوات التجاوز",
  first5YearsIndemnity: "تعويض السنوات الخمس الأولى",
  monthly_indemnity: "التعويض الشهري",
  paid_leave_balance_amount: "مبلغ رصيد الإجازة المدفوعة",
  total_indemnity: "التعويض الإجمالي",
  civil_id_status: "حالة البطاقة المدنية",
  passport_status: "حالة جواز السفر",
  residency_status: "حالة الإقامة",
  valid: "صالح",
  expired: "منتهية الصلاحية",
};
