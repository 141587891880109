import { Translate } from './Tranlate';

const PrintProfile = (data, lang) =>{
    let printWindow = window.open("", "_blank");

    let style = `
    * {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }
    .row{
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }
    .col-md-6{
        position: relative;
        width: 50%;
        padding-right: 15px;
        padding-left: 15px;
    }
    .col-md-12{
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }
    @media (min-width: 768px) {
        .col-md-6 {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
    .card {
        margin-bottom: 1.875rem;
        background-color: #fff;
        transition: all 0.5s ease-in-out;
        position: relative;
        border: 0px solid transparent;
        border-radius: 0.25rem;
        box-shadow: 0px 12px 23px 0px rgba(112, 112, 112, 0.04);
        height: calc(100% - 30px);
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-clip: border-box;
    }
    .card-body {
        flex: 1 1 auto;
        min-height: 1px;
        padding: 1.25rem;
    }
    .emp-profile .basic .job-title, .emp-profile .basic .department {
        line-height: 1;
    }
    table{
        width: 100%
    }
    .mb-1, .my-1 {
        margin-bottom: 0.25rem !important;
    }
    .mb-2, .my-2 {
        margin-bottom: 0.5rem !important;
    }
    .emp-profile .basic .date-join {
        font-size: 14px;
    }
    li {
        display: flex;
    }
    .basic {
        border-right: 2px dotted #dedede;
    }
    ul{
        list-style: none;
    }
    .personal-info .title {
        color: #373b3e;
        font-weight: 500;
        margin-bottom: 8px;
        width: 40%;
    }
    .personal-info .text {
        color: #373b3e;
        display: block;
        overflow: hidden;
        margin-bottom: 8px;
        width: 60%;
    }
    .profile{
        margin-top: 5rem
    }
    .card-title {
        font-size: 20px;
        font-weight: 500;
        color: #000;
        text-transform: capitalize;
        margin-bottom: 0.75rem;
    }
    .text-center{
        text-align: center
    }
    .assets-col{
        display: grid; 
        grid-template-columns: auto auto auto; 
        gap: 8px;
    }
    .badge {
        line-height: 1.5;
        border-radius: 0.25rem;
        font-size: 14px;
        font-weight: 600;
        padding: 4px 10px;
        border: 1px solid;
    }
    `
    let header = `
    <div class="emp-profile">
        <div>
            <div>
                <div class="row">
                    <div class="col-md-6 basic">
                        <div>
                            <h3 class='name mb-1'>${data?.name}</h3>
                            <p class='job-title mb-1'>${data?.job_title}</p>
                            <p class="department mb-2">${data?.department?.name}</p>
                            <p class='date-join'>${Translate[lang].date_of_join}: ${data?.start_date?.split('T')[0]}</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <ul class="personal-info">
                            <li>
                                <div class="title">${Translate[lang].civil_id}:</div>
                                <div class="text">
                                    ${data?.civil_id}
                                </div>
                            </li>
                            <li>
                                <div class="title">${Translate[lang].nationality}:</div>
                                <div class="text">${data?.nationality}</div>
                            </li>
                            <li>
                                <div class="title">${Translate[lang].personal_email}:</div>
                                <div class="text">${data?.personal_email}</div>
                            </li>
                            <li>
                                <div class="title">${Translate[lang].company_email}:</div>
                                <div class="text">${data?.company_email}</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    `

    let assValues = (arr) => {
        let info = ``
        for(let i=0; arr?.length > i; i++){
            info += `<span class="badge" key=${i}>${arr[i]?.item}</span>`
        }
        return info
    }

    let emp_assets = ``
    for(let i=0; data.employee_assets?.length > i; i++){
        emp_assets += `<tr key=${i} class='text-center'>
        <td>
            <strong>${data.employee_assets[i]?.asset?.id}</strong>
        </td>
        <td>
            ${data.employee_assets[i]?.asset?.name}
        </td>
        <td>
            ${data.employee_assets[i]?.asset?.serial_number || '-'}
        </td>
        <td>
            ${!!data.employee_assets[i]?.asset?.asset_items?.length ? `<div class='assets-col'>
                ${assValues(data.employee_assets[i]?.asset?.asset_items)}
            </div>` : '-'}
        </td>
        <td>
            ${data.employee_assets[i]?.asset?.asset}
        </td>
        </tr>`
    }
    let benefits = ``
    for(let i=0; data.employee_assets?.length > i; i++){
        benefits += `<tr key=${i} class='text-center'>
        <td>
            <strong>${data.benefits[i]?.id}</strong>
        </td>
        <td>
            ${data.benefits[i]?.type}
        </td>
        <td>
            ${data.benefits[i]?.increase}
        </td>
        <td>
            ${data.benefits[i]?.month}
        </td>
        <td>
            ${data.benefits[i]?.year}
        </td>
        </tr>`
    }

    let profile = `
    <div class="profile">
        <div class='row'>
            <div class='col-md-6'>
                <div class='card'>
                    <div class='card-body'>
                    <div>
                        <h3 class="card-title">${Translate[lang].personal_informations}</h3>
                        <ul class="personal-info">
                            <li>
                            <div class="title">${Translate[lang].department}</div>
                            <div class="text">${data?.department?.name}</div>
                            </li>
                            <li>
                            <div class="title">${Translate[lang].civil_id}</div>
                            <div class="text">${data?.civil_id}</div>
                            </li>
                            <li>
                            <div class="title">${Translate[lang].job_title}</div>
                            <div class="text">${data?.job_title}</div>
                            </li>
                            <li>
                            <div class="title">${Translate[lang].passport_no}</div>
                            <div class="text">${data?.passport_no || '-'}</div>
                            </li>
                            <li>
                            <div class="title">${Translate[lang].passport_expiry_date}</div>
                            <div class="text">${data?.passport_expiry_date || '-'}</div>
                            </li>
                            <li>
                            <div class="title">${Translate[lang].nationality}</div>
                            <div class="text">${data?.nationality}</div>
                            </li>
                            <li>
                            <div class="title">${Translate[lang].salary}</div>
                            <div class="text">${data?.salary} ${Translate[lang].kwd}</div>
                            </li>
                        </ul>
                    </div>
                    </div>
                </div>
            </div>
            <div class='col-md-6'>
                <div class='card' style="height: auto">
                    <div class='card-body'>
                            <h3 class="card-title">${Translate[lang].bank}</h3>
                            <ul class="personal-info">
                                <li>
                                    <div class="title">${Translate[lang].iban}</div>
                                    <div class="text">${data?.iban}</div>
                                </li>
                            </ul>
                    </div>
                </div>
                <div class='card' style="height:auto">
                    <div class='card-body'>
                            <h3 class="card-title">${Translate[lang].loan}</h3>
                            ${data?.loans?.length > 0 ? `
                                ${data?.loans?.map(res=> {
                                    return `<ul class="personal-info" key=${res?.id}>
                                    <li>
                                        <div class="title">${Translate[lang].loan}</div>
                                        <div class="text">${res?.loan} ${Translate[lang].kwd}</div>
                                    </li>
                                    <li>
                                        <div class="title">${Translate[lang].no_of_installments}</div>
                                        <div class="text">${res?.no_of_installments}</div>
                                    </li>
                                    <li>
                                        <div class="title">${Translate[lang].start_date}</div>
                                        <div class="text">${res?.start_date?.split('T')[0]}</div>
                                    </li>
                                    </ul>`
                                })}` : Translate[lang].there_are_no_data} 
                    </div>
                </div>
                <div class='card' style="height:auto">
                    <div class='card-body'>
                            <h3 class="card-title">${Translate[lang].allowances}</h3>
                            ${data?.allowance?.length > 0 ? `<ul class="personal-info">
                                ${data?.allowance?.map(allow=>{
                                    return `<li key=${allow?.id}>
                                        <div class="title">${allow?.name}</div>
                                        <div class="text">${allow?.fee}</div>
                                    </li>`
                                })}
                            </ul>` : Translate[lang].there_are_no_data} 
                    </div>
                </div>
            </div>
            
            ${!!data.employee_assets?.length && `<div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <h3 class="card-title">${Translate[lang].assets}</h3>
                            <table>
                                <thead>
                                    <tr class='text-center'>
                                        <th>
                                            <strong>I.D</strong>
                                        </th>
                                        <th>
                                            <strong>${Translate[lang]?.name}</strong>
                                        </th>
                                        <th>
                                            <strong>${Translate[lang]?.serial_number}</strong>
                                        </th>
                                        <th>
                                            <strong>${Translate[lang]?.items}</strong>
                                        </th>
                                        <th>
                                            <strong>${Translate[lang]?.type}</strong>
                                        </th>
                                    </tr>
                                </thead>

                            <tbody>
                                ${emp_assets}
                            </tbody>
                        </table>
                        </div>
                    </div>
            </div>`}
            ${!!data.benefits?.length && `<div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <h3 class="card-title">${Translate[lang].benefits}</h3>
                            <table>
                                <thead>
                                    <tr class='text-center'>
                                        <th>
                                            <strong>I.D</strong>
                                        </th>
                                        <th>
                                            <strong>${Translate[lang]?.type}</strong>
                                        </th>
                                        <th>
                                            <strong>${Translate[lang]?.increase}</strong>
                                        </th>
                                        <th>
                                            <strong>${Translate[lang]?.month}</strong>
                                        </th>
                                        <th>
                                            <strong>${Translate[lang]?.year}</strong>
                                        </th>
                                    </tr>
                                </thead>

                            <tbody>
                                ${benefits}
                            </tbody>
                        </table>
                        </div>
                    </div>
            </div>`}
            <div class='col-md-6'>
                <div class='card'>
                    <div class='card-body'>
                                <h3 class="card-title">${Translate[lang].provision}</h3>
                                <ul class="personal-info">
                                    <li>
                                        <div class="title">${Translate[lang].accrued_leave}</div>
                                        <div class="text">${data?.accrued_leave}</div>
                                    </li>
                                    <li>
                                        <div class="title">${Translate[lang].provision}</div>
                                        <div class="text">${data?.provision_KWD}</div>
                                    </li>
                                </ul>
                        </div>
                    </div>
            </div>
            <div class='col-md-6'>
                <div class='card'>
                    <div class='card-body'>
                                <h3 class="card-title">${Translate[lang].sick_leave}</h3>
                                <ul class="personal-info">
                                    <li>
                                        <div class="title">${Translate[lang].sick_leave}</div>
                                        <div class="text">${data?.sick_leave}</div>
                                    </li>
                                </ul>
                        </div>
                    </div>
            </div>
            <div class='col-md-6'>
                <div class='card'>
                    <div class='card-body'>
                                <h3 class="card-title">${Translate[lang].indemnity}</h3>
                                <ul class="personal-info">
                                    <li>
                                        <div class="title w-50">${Translate[lang].accrued_leave}</div>
                                        <div class="text w-50 px-5">${data?.accrued_leave}</div>
                                    </li>
                                    <li>
                                        <div class="title w-50">${Translate[lang].daily_indemnity}</div>
                                        <div class="text w-50 px-5">${data?.dailyIndemnity}</div>
                                    </li>
                                    <li>
                                        <div class="title w-50">${Translate[lang].exceeding_years_indemnity}</div>
                                        <div class="text w-50 px-5">${data?.exceedingYearsIndemnity}</div>
                                    </li>
                                    <li>
                                        <div class="title w-50">${Translate[lang].first5YearsIndemnity}</div>
                                        <div class="text w-50 px-5">${data?.first5YearsIndemnity}</div>
                                    </li>
                                    <li>
                                        <div class="title w-50">${Translate[lang].monthly_indemnity}</div>
                                        <div class="text w-50 px-5">${data?.monthlyIndemnity}</div>
                                    </li>
                                    <li>
                                        <div class="title w-50">${Translate[lang].paid_leave_balance_amount}</div>
                                        <div class="text w-50 px-5">${data?.paidLeaveBalanceAmount}</div>
                                    </li>
                                    <li>
                                        <div class="title w-50">${Translate[lang].total_indemnity}</div>
                                        <div class="text w-50 px-5">${data?.totalIndemnity}</div>
                                    </li>
                                </ul>
                    </div>
                </div>
            </div>
        </Row>
    </div>`

    let htmlCode = `
        <html>
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <style>
                    ${style}
                </style>
                <title>Profile</title>
            </head>
            <body style="width: 97%; direction: ${lang==='en' ? 'ltr' : 'rtl'}; margin: 30px auto; padding: 0"> 
                ${header}
                ${profile}
            </body>
        </html>
    `;

    printWindow.document.write(htmlCode);
    printWindow.document.close();

    setTimeout(() => {
      printWindow.print();
    }, 1500);
}
export default PrintProfile