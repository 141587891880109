import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import './style.scss'
import { Translate } from "../../Enums/Tranlate";
import AdminService from "../../../services/AdminService";
import { useNavigate } from "react-router-dom";
import departments from '../../../images/icons/department1.png'
import employee from '../../../images/icons/employee1.png'
import financial from '../../../images/icons/financial1.png'
import administrator from '../../../images/icons/administrator1.png'
import resignation from '../../../images/icons/resignation.png'

const Dashboard = () => {
  const [formData ,setFormData] = useState({
    totalCompany: '0',
    totalPeople: '0',
    totalLeave: '0',
    totalAssets: '0',
    totalAdmins: '0',
    totalResignation: '0'
  })
  const [loading, setLoading] = useState(false)
  const lang = useSelector(state=> state.auth.lang)
  const navigate = useNavigate()

  useEffect(()=>{
    setLoading(true)
    new AdminService().getDashboard().then(res=>{
      if(res?.status){
        setFormData(res.data.data)
      }
      setLoading(false)
    }).catch(()=> setLoading(false))
  },[])

  if(loading) {
    return <Row>
      {Object.entries(formData)?.map(data=>{
        return <Col className="col-md-4 col-sm-12">
          <Card style={{height: '128.75px'}}>
            <Card.Body>
              <div class="skeleton-loader">
                  <div class="loader-header"></div>
                  <div class="loader-content"></div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      })}
    </Row>
  }

  return (
        <div className="row dashboard">
          <div className="col-md-4 col-sm-12">
            <div className="card" style={{background: 'var(--badge-primary)'}} onClick={()=> navigate('/departments')}>
              
              <div className="card-body">
                <h2>{formData.totalCompany}</h2>
                {/* <i className='la la-building'></i> */}
                <img src={departments} alt='departments' width={50} />
                <div>
                  <span className="fs-16 font-w600">
                    {Translate[lang]?.departments}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="card" style={{background: '#D3DDDC'}}  onClick={()=> navigate('/employees')}>
              
              <div className="card-body">
                <h2>{formData.totalPeople}</h2>
                {/* <i className='la la-users'></i> */}
                <img src={employee} alt='employee' width={50} />
                <div>
                  <span className="fs-16 font-w600">
                     {Translate[lang]?.employees}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="card" style={{background: 'var(--badge-primary)'}} onClick={()=> navigate('/employees',{state: 'vacations'})}>
            
              <div className="card-body">
                <h2>{formData.totalLeave}</h2>
                <img src={employee} alt='emp' width={50} />
                {/* <i className='la la-users'></i> */}
                <div>
                  <span className="fs-16 font-w600">
                     {Translate[lang]?.employees_on_vacation}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="card" style={{background: '#D3DDDC'}} onClick={()=> navigate('/assets')}>
            
              <div className="card-body">
                <h2>{formData.totalAssets}</h2>
                <img src={financial} alt='financial' width={50} />
                {/* <i class="flaticon-381-notepad"></i> */}
                <div>
                  <span className="fs-16 font-w600">
                     {Translate[lang]?.assets}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="card" style={{background: 'var(--badge-primary)'}} onClick={()=> navigate('/admins')}>
            
              <div className="card-body">
                <h2>{formData.totalAdmins}</h2>
                <img src={administrator} alt='administrator' width={50} />
                {/* <i className='la la-user-shield'></i> */}
                <div>
                  <span className="fs-16 font-w600">
                     {Translate[lang]?.admins}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="card" style={{background: '#D3DDDC'}} onClick={()=> navigate('/employees',{state: 'resignation'})}>
            
              <div className="card-body">
                <h2>{formData.totalResignation}</h2>
                <img src={resignation} alt='resignation' width={50} />
                {/* <i className='la la-clock'></i> */}
                <div>
                  <span className="fs-16 font-w600">
                     {Translate[lang]?.resignation}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
  );
};

export default Dashboard;
