import { useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../common/Loader";
import NoData from "../../common/NoData";
import Pagination from "../../common/Pagination/Pagination";
import { Translate } from "../../Enums/Tranlate";
import CardItem from "./CardItem";
import OrganizationService from "../../../services/OrganizationService";

const Organization = () => {
  const [data, setData] = useState(null);
  const [hasData, setHasData] = useState(null);
  const [search, setSearch] = useState(null);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const organizationService = new OrganizationService();
  const Auth = useSelector((state) => state.auth?.auth);
  const isExist = (data) => Auth?.admin?.admin_roles?.includes(data);
  const lang = useSelector((state) => state.auth?.lang);

  return (
    <div className="admin">
        <Card className="mb-3">
        <Card.Body className="assets-div d-flex justify-content-between p-3 align-items-center">
          <div className="input-group w-50">
            <input 
                type="text" 
                style={{borderRadius: '8px',
                color: 'initial',
                padding: '18px 16px'}}
                className="form-control"
                placeholder={`${Translate[lang]?.search_by} I.D, ${Translate[lang]?.name}`}
                value={search}
                onChange={e=> setSearch(e.target.value)} 
            />
            <div className="flaticon-381-search-2"
              style={{position: 'absolute',zIndex:'1', right: lang === 'en' && '16px', left: lang === 'ar' && '16px', top: '50%', transform: 'translate(0, -50%)'}}
            ></div>
          </div>
          <div className="add-assets">
            {isExist("add_organization") && <Link to='/organization/add-organization' className='btn btn-primary me-2 h-75'>
              <i className="la la-plus mx-1"></i>
              {Translate[lang]?.add} {Translate[lang]?.organization}
            </Link>}
          </div>
        </Card.Body >
      </Card>
      <Card>
        <Card.Body className={`${hasData === 0 ? "text-center" : ""}`}>
          {loading && (
            <div style={{ height: "300px" }}>
              <Loader />
            </div>
          )}
          {hasData === 1 && !loading && (
            <Table responsive>
              <thead>
                <tr>
                  <th className="px-2">
                    <strong>I.D</strong>
                  </th>
                  <th className="px-2">
                    <strong>{Translate[lang]?.name}</strong>
                  </th>
                  <th className="px-2">
                    <strong>{Translate[lang]?.attachments}</strong>
                  </th>
                  <th>
                    <strong>{Translate[lang]?.view}</strong>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => {
                    return (
                      <CardItem
                        key={index}
                        index={index}
                        item={item}
                        setShouldUpdate={setShouldUpdate}
                      />
                    );
                  })}
              </tbody>
            </Table>
          )}
          {hasData === 0 && <NoData />}
          <Pagination
            setData={setData}
            service={organizationService}
            shouldUpdate={shouldUpdate}
            setHasData={setHasData}
            setLoading={setLoading}
            search={search}
          />
        </Card.Body>
      </Card>
    </div>
  );
};
export default Organization;
