import dashboard from '../../images/icons/dashboard1.png'
import administrator from '../../images/icons/administrator1.png'
import shield from '../../images/icons/shield1.png'
import department from '../../images/icons/department1.png'
import assets from '../../images/icons/financial1.png'
import employee from '../../images/icons/employee1.png'
import attendance from '../../images/icons/attendance1.png'
import notification from '../../images/icons/notification1.png'
import holiday from '../../images/icons/holidays1.png'
import organization from '../../images/icons/organization.png'

export const MenuList = [
  // Statics
  {
    title: "Statics",
    classsChange: "mm-collapse",
    iconStyle: <img src={dashboard} width={22} alt='shield' />,
    // iconStyle: <i class="lab la-buffer"></i>,
    to: "",
    text: "statics",
  },
  // Admins
  {
    title: "Admins Management",
    classsChange: "mm-collapse",
    iconStyle: <img src={administrator} width={22} alt='shield' />,
    // iconStyle: <i className="la la-user-shield"></i>,
    to: "admins",
    text: "admins",
  },
  // Rules
  {
    title: "Rules",
    classsChange: "mm-collapse",
    iconStyle: <img src={shield} width={22} alt='shield' />,
    // iconStyle: <i className="la la-shield"></i>,
    to: "rules",
    text: "rules",
  },
  // Departments
  {
    title: "Departments",
    classsChange: "mm-collapse",
    iconStyle: <img src={department} width={22} alt='shield' />,
    // iconStyle: <i className="la la-codepen"></i>,
    to: "departments",
    text: "departments",
  },
  // Assets
  {
    title: "Assets",
    classsChange: "mm-collapse",
    iconStyle: <img src={assets} width={22} alt='shield' />,
    // iconStyle: <i className="la la-cube"></i>,
    to: "assets",
    text: "assets",
  },
  // Employees
  {
    title: "Employees",
    classsChange: "mm-collapse",
    iconStyle: <img src={employee} width={22} alt='shield' />,
    // iconStyle: <i className="la la-users"></i>,
    to: "employees",
    text: "employees",
  },
  // Attendance
  {
    title: "Attendance",
    classsChange: "mm-collapse",
    iconStyle: <img src={attendance} width={22} alt='shield' />,
    // iconStyle: <i className="flaticon-381-notepad"></i>,
    to: "attendance",
    text: "attendance",
  },
  // Notification
  {
    title: "Notification",
    classsChange: "mm-collapse",
    iconStyle: <img src={notification} width={22} alt='shield' />,
    // iconStyle: <i className="la la-bell"></i>,
    to: "notification",
    text: "notification",
  },
  // Holiday
  {
    title: "Holiday",
    classsChange: "mm-collapse",
    iconStyle: <img src={holiday} width={22} alt='shield' />,
    // iconStyle: <i className="la la-clock"></i>,
    to: "holiday",
    text: "holiday",
  },
  // Organization
  {
    title: "Organization",
    classsChange: "mm-collapse",
    iconStyle: <img src={organization} width={22} alt='shield' />,
    // iconStyle: <i className="la la-cubes"></i>,
    to: "organization",
    text: "organization",
  },
  
  // Human resources
  // {
  //   title: "Human Resources",
  //   classsChange: "mm-collapse",
  //   iconStyle: <i className="la la-users"></i>,
  //   text: "human_resources",
  //   content : [
  //     // Employees
  //     {
  //       title: "Employees",
  //       to: "employees",
  //       text: "employees",
  //     },
  //     // Departments
  //     {
  //       title: "Departments",
  //       to: "departments",
  //       text: "departments",
  //       rule: "departments",
  //     },
  //     // Assets
  //     {
  //       title: "",
  //       to: "assets",
  //       text: "assets",
  //     },
  //     // Attendance
  //     {
  //       title: "Attendance",
  //       to: "attendance",
  //       text: "attendance",
  //     },
  //     // Salary Certificate
  //     {
  //       title: "Employee Letters",
  //       to: "employee-letters",
  //       text: "employee_letters",
  //     },
  //     // Allowances
  //     {
  //       title: "Allowances",
  //       to: "allowances",
  //       text: "allowances",
  //     },
  //     // Loans
  //     {
  //       title: "Loans",
  //       to: "loans",
  //       text: "loans",
  //     },
  //     // Requests
  //     {
  //       title: "Requests",
  //       to: "requests",
  //       text: "requests",
  //     },
  //     // Notification
  //     {
  //       title: "Notification",
  //       to: "notification",
  //       text: "notification",
  //     },
  //     // Holiday
  //     {
  //       title: "Holiday",
  //       to: "holiday",
  //       text: "holiday",
  //     },
  //   ]
  // },
  
  // // Project
  // {
  //   title: "Projects",
  //   classsChange: "mm-collapse",
  //   iconStyle: <i className="la la-cubes"></i>,
  //   to: "projects",
  //   text: "projects",
  // }, 
  // // Holiday
  // {
  //   title: "Holiday",
  //   classsChange: "mm-collapse",
  //   iconStyle: <i className="la la-slack"></i>,
  //   to: "holiday",
  //   text: "holiday",
  // },
  // // Leads
  // {
  //   title: "Leads",
  //   classsChange: "mm-collapse",
  //   iconStyle: <i className="la la-cube"></i>,
  //   to: "leads",
  //   text: "leads",
  // },
  // // Payment Link
  // {
  //   title: "Payment Link",
  //   classsChange: "mm-collapse",
  //   iconStyle: <i className="la la-link"></i>,
  //   to: "payment-link",
  //   text: "payment_link",
  // },
  // // Payment
  // {
  //   title: "Payment",
  //   classsChange: "mm-collapse",
  //   iconStyle: <i className="la la-money"></i>,
  //   to: "payment",
  //   text: "payment",
  // },
  // // Financial
  // {
  //   title: "Financial",
  //   classsChange: "mm-collapse",
  //   iconStyle: <i className="la la-pie-chart"></i>,
  //   to: "financial",
  //   text: "financial",
  // },
  // Setting
//   {
//     title:'control',
//     text:'control',
//     classsChange: 'mm-collapse',
//     iconStyle: <i className='la la-desktop'></i>,
//     content : [
//         {
//           title:'Banners',
//           text:'banners',
//           to: 'website-banners',
//           rule: 'website',
//         }, {
//           title:'Activities',
//           text:'activities',
//           to: 'activities',
//           rule: 'activities',
//         }, {
//           title:'Branches and Markets',
//           text:'branches_and_markets',
//           to: 'branches-and-markets',
//           rule: 'website',
//         }, {
//           title:'Offers',
//           text:'offers',
//           to: 'offers',
//           rule: 'website',
//         }, {
//           title:'Members',
//           text:'members',
//           to: 'members',
//           rule: 'website',
//         }, {
//           title:'Shareholders',
//           text:'shareholders',
//           to: 'shareholders',
//           rule: 'website',
//         }, {
//           title:'Shareholders Requests',
//           text:'shareholders_requests',
//           to: 'shareholders_requests',
//           rule: 'website',
//         }, {
//           title:'Family Card',
//           text:'family_card',
//           to: 'family_card',
//           rule: 'website',
//         }, {
//           title:'Products',
//           text:'products',
//           to: 'products',
//           rule: 'website',
//         },  {
//           title:'Profits',
//           text:'profits',
//           to: 'profits',
//           rule: 'website',
//         }, {
//           title:'News',
//           text:'news',
//           to: 'news',
//           rule: 'website',
//         }, {
//           title:'Diwans',
//           text:'diwans',
//           to: 'diwans',
//           rule: 'website',
//         }, {
//           title:'Contact Us',
//           text:'contact_us',
//           to: 'contact-us',
//           rule: 'website',
//         },{
//           title:'Notification',
//           text:'notification',
//           to: 'notification',
//           rule: 'website',
//         },{
//           title:'Social Media',
//           text:'social_media',
//           to: 'social',
//           rule: 'website',
//         },{
//           title:'Privacy',
//           text:'privacy',
//           to: 'privacy',
//           rule: 'website',
//         },
//     ]
// },
// Notification
  // {
  //     title:'Notification',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className='la la-bell'></i>,
  //     to: 'notification',
  //     text: 'notification',
  // },
  // Tankers
  // {
  //   title: "Tankers",
  //   classsChange: "mm-collapse",
  //   iconStyle: <i className="la la-clock"></i>,
  //   to: "tankers",
  //   text: "tankers",
  // },
  // Wallet Management
  // {
  //     title:'Wallet Management',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className='la la-dollar'></i>,
  //     to: 'wallet-management',
  //     text: 'wallet_management',
  // },
  // Time Slot
  // {
  //     title:'Time Slot',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className='la la-clock'></i>,
  //     to: 'time-slot',
  //     text: 'time_slot',
  // },
  // Banners
  // {
  //   title: "Banners",
  //   classsChange: "mm-collapse",
  //   iconStyle: <i class="las la-image"></i>,
  //   to: "banners",
  //   text: "banners",
  // },
  // Activities
  // {
  //   title: "Activities",
  //   classsChange: "mm-collapse",
  //   iconStyle: <i className="la la-cubes"></i>,
  //   to: "",
  //   text: "activities",
  // },
  // Time Slot
  // {
  //   title: "Time Slot",
  //   classsChange: "mm-collapse",
  //   iconStyle: <i className="la la-clock"></i>,
  //   to: "time-slot",
  //   text: "time_slot",
  // },
  // Ad Screen
  // {
  //   title: "Ad Screen",
  //   classsChange: "mm-collapse",
  //   iconStyle: <i className="la la-thumb-tack" />,
  //   to: "ad-screen",
  //   text: "ad_screen",
  // },
  // Setting
  // {
  //     title:'Setting',
  //     text:'setting',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className='la la-cog'></i>,
  //     content : [
  //         // {
  //         //     title:'Delivery',
  //         //     text:'delivery',
  //         //     to: 'delivery',
  //         //     rule: 'delivery',
  //         // },
  //         {
  //             title:'Social Media',
  //             text:'social_media',
  //             to: 'social',
  //             rule: 'social_media',
  //         },
  //         // {
  //         //     title:'Currency',
  //         //     text: 'currency',
  //         //     to: 'currency',
  //         //     rule: 'currency',
  //         // },
  //         // {
  //         //     title:'Payment',
  //         //     text: 'payment',
  //         //     to: 'payment',
  //         //     rule: 'payment',
  //         // },
  //         {
  //             title:'Pages',
  //             text: 'pages',
  //             to: 'pages',
  //             rule: 'static_pages',
  //         },
  //     ]
  // },

  // <i className='la la-cubes'></i>,
  // <i className='la la-cube'></i>,
  // <i className="la la-slack" />,
  // <i className="flaticon-381-notepad" />,
  // <i className="la la-qrcode"></i>
  // <i className='la la-truck'></i>
  // <i className='la la-pie-chart'></i>
  // <i className='la la-dollar'></i>
  // <i className="la la-wrench" />
];
